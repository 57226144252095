import React from 'react';
import VideoPlayer from 'components/media_player/video_player';
import Player from 'components/ziggeo/player';

const MediaQuestion = ({ ziggeo, audio }) => {
    if (!(ziggeo)) return null;
    let player;

    if (ziggeo.media_file_url) {
        player = <VideoPlayer ziggeo={ziggeo} audio={audio} />;
    } else {
        player = <Player audio={audio} ziggeo_token={ziggeo.token} />;
    }
    return <div className="u-txt--center">{player}</div>;
};

export default MediaQuestion;
