import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import ReactPlayer from 'react-player/lazy';

const useStyles = makeStyles(() => ({
    videoWrapper: {
        '& video': {
            maxWidth: '100%'
        }
    },
    audioWrapper: {
        '& video': {
            minWidth: 400,
            border: '2px solid #EBECEE',
            borderRadius: 4
        },
        '& audio': {
            minWidth: 400,
            minHeight: 50
        }
    },
    playerSmall: {
        maxWidth: '100%',
        maxHeight: '100%',
        '& video': {
            maxWidth: '100%',
            maxHeight: '100%'
        },
        '& audio': {
            minWidth: 250
        }
    }
}));

const VideoPlayer = ({ ziggeo, audio, className = '' }) => {
    const classes = useStyles();
    if (!ziggeo.media_file_url) return null;

    return (
        <div>
            <ReactPlayer
                url={ziggeo.media_file_url}
                controls
                width="auto"
                height="100%"
                className={clsx(classes.videoWrapper, audio && classes.audioWrapper, 'video_player', className)}
                config={{
                    file: {
                        attributes: { controlsList: 'nodownload' }
                    }
                }}
            />
        </div>
    );
};

export default VideoPlayer;
