import React from 'react';
import Player from 'components/ziggeo/player';

const MediaQuestion = ({ question: { ziggeo } }, audio) => (
    <div className="u-txt--center">
        {
            ziggeo && ziggeo.token
            && <Player audio={audio} ziggeo_token={ziggeo.token} />
        }
    </div>
);

export default MediaQuestion;
