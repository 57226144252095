import React from 'react';
import clsx from 'clsx';
import VideoPlayer from 'components/media_player/video_player';
import Player from 'components/ziggeo/player';
import { withStyles } from '@mui/styles';
import styles from './styles';

const AnswerOptionMedia = ({ answer: { ziggeo, type }, classes }) => {
    const { wrapper } = classes;

    const handleClick = (e) => {
        e.stopPropagation();
    };

    if (!ziggeo) return null;

    const isAudio = type === 3;

    let player;
    if (ziggeo.media_file_url) {
        player = <VideoPlayer audio={isAudio} ziggeo={ziggeo} className={clsx(isAudio && 'audioType')} />;
    } else {
        player = <Player audio={isAudio} ziggeo_token={ziggeo.token} />;
    }

    return (
        <div role="contentinfo" className={wrapper} onClick={handleClick}>
            {player}
        </div>
    );
};

export default withStyles(styles)(AnswerOptionMedia);
